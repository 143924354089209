import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TaskService } from './task.service';
// import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
// import { CdkDrag } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss']
})
export class TaskComponent implements OnInit {
  @ViewChild('boundary') boundary: ElementRef;

  public id: string;
  public iid: string;

  public ratio = 0;
  public oWidht = 900;
  public oHeight = 1145;

  public o1Width = 221;
  public o1Height = 214;

  public o2Width = 221;
  public o2Height = 203;

  public o3Width = 221;
  public o3Height = 188;

  public o4Width = 219;
  public o4Height = 184;

  public o5Width = 36;
  public o5Height = 38;

  // public o1Width = 250;
  // public o1Height = 242;

  // public o2Width = 249;
  // public o2Height = 229;

  // public o3Width = 250;
  // public o3Height = 213;

  // public o4Width = 244;
  // public o4Height = 205;

  // public o5Width = 51;
  // public o5Height = 53;

  public width = 900;
  public height = null;
  
  private tasks = {
    11: {
      title: 'SAMIEC TWÓJ WRÓG',
      content: '/assets/images/11/content.png'
    },
    12: {
      title: 'NIEBEZPIECZNE<br />PRZEDMIOTY',
      content: '/assets/images/12/content.png'
    },
    13: {
      title: 'TRENING<br />SPRAWNOŚCIOWY',
      content: '/assets/images/13/content.png'
    },
    21: {
      title: 'LIGA KOBIET',
      content: '/assets/images/21/content.png'
    },
    22: {
      title: 'ORGANIZACJA ŻYCIA<br />SPOŁECZNEGO',
      content: '/assets/images/22/content.png'
    },
    23: {
      title: 'WIESZANIE JABŁEK',
      content: '/assets/images/23/content.png'
    },
    31: {
      title: 'NATURALIZACJA',
      content: '/assets/images/31/content.png'
    },
    32: {
      title: 'WEŹ PIGUŁKĘ',
      content: '/assets/images/32/content.png'
    },
    33: {
      title: 'PARTENOGENEZA',
      content: '/assets/images/33/content.png'
    }
  }

  constructor(
    private route: ActivatedRoute,
    private taskService: TaskService
  ) { }

  ngAfterViewInit() {
    setTimeout(() => {
      this.calc();
    }, 500);
  }
 
  ngOnInit(): void {
    this.route.params
      .subscribe(params => {
        this.id = params?.id;
        this.iid = params?.iid;
      }
    );    
  }  

  onResize(event) {
    this.calc();
  }
  
  calc() {  
    if (this.isTask('31')) {
      let roznica = this.oWidht - this.boundary.nativeElement.offsetWidth;
      this.ratio = roznica / this.oWidht;
      this.height = this.oHeight - (this.oHeight * this.ratio); 
    }
  }

  getRatio(value) {
    return value - (value * this.ratio);
  }

  getKey() {
    return this.id.toString() + this.iid.toString();
  }

  getContent() {
    let task = this.tasks[this.getKey()];
    return (task !== undefined)? task.content : this.getKey();
  }

  getTitle() {    
    let task = this.tasks[this.getKey()];
    return (task !== undefined)? task.title : this.getKey();
  }

  isTask(task: string) {
    return (task === this.getKey());
  }
}
