<app-header [title]="getTitle()"></app-header>

<div class="content">
    <img [src]="getContent()" alt="content" />
</div>

<div *ngIf="isTask('11')" class="content">
    <img src="/assets/images/11/zadanie.png" alt="item" />    
</div>

<div *ngIf="isTask('12')" class="content-items">
    <img src="/assets/images/12/brzytwa.png" alt="item" />
    <img src="/assets/images/12/pilot.png" alt="item" />
    <img src="/assets/images/12/szpatulka.png" alt="item" />
    <img src="/assets/images/12/sluchawki.png" alt="item" />
    <img src="/assets/images/12/pilka.png" alt="item" />
    <img src="/assets/images/12/lampka.png" alt="item" />
</div>

<div *ngIf="isTask('21')" class="content">
    <img src="/assets/images/21/zadanie.png" alt="item" />    
</div>

<div *ngIf="isTask('22')" class="content">
    <img src="/assets/images/22/zadanie1.png" alt="item" />    
    <img src="/assets/images/22/zadanie2.png" alt="item" />    
    <img src="/assets/images/22/zadanie3.png" alt="item" />       
    <img src="/assets/images/22/zadanie4.png" alt="item" />        
</div>

<div *ngIf="isTask('31')" class="content mb" (window:resize)="onResize($event)">
    <div #boundary class="example-boundary" [style.width.px]="width" [style.height.px]="height">
        <div class="example-box element-4" cdkDragBoundary=".example-boundary" cdkDrag
            [style.width.px]="getRatio(o4Width)" 
            [style.height.px]="getRatio(o4Height)">
        </div>
        <div class="example-box element-1" cdkDragBoundary=".example-boundary" cdkDrag
            [style.width.px]="getRatio(o1Width)" 
            [style.height.px]="getRatio(o1Height)">            
        </div>
        <div class="example-box element-2" cdkDragBoundary=".example-boundary" cdkDrag
            [style.width.px]="getRatio(o2Width)" 
            [style.height.px]="getRatio(o2Height)">
        </div>
        <div class="example-box element-3" cdkDragBoundary=".example-boundary" cdkDrag
            [style.width.px]="getRatio(o3Width)" 
            [style.height.px]="getRatio(o3Height)">
        </div>
        <div class="example-box element-5" cdkDragBoundary=".example-boundary" cdkDrag
            [style.width.px]="getRatio(o5Width)" 
            [style.height.px]="getRatio(o5Height)">
        </div>        
    </div>
</div>

<div *ngIf="isTask('32')" class="content mb">
    <img src="/assets/images/32/zadanie1.png" alt="item" />    
    <img src="/assets/images/32/zadanie2.png" alt="item" />    
    <img src="/assets/images/32/zadanie3.png" alt="item" />        
</div>