import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  private oHeight = 1145;
  public height = null;
  
  constructor() { }

  getHeight(roznica: number = 0) {
    return this.oHeight -  roznica;
  }
}
