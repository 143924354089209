<div class="header">
    <div class="header-left">
        <img src="assets/images/logo.png" alt="logo" />
    </div>
    <div class="header-right">
        <h1>SEKSMISJA</h1>
        <h2 [innerHtml]="title"></h2>
    </div>
</div>

